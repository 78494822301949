var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"checkout-progress-bar mt-3"},[_c('div',{staticClass:"progress-item d-flex",class:{
            selected: _vm.currentRouteName === 'Checkout',
            completed: _vm.checkout == true,
        }},[(_vm.currentRouteName === 'Summar')?_c('router-link',{attrs:{"to":{ name: 'Checkout' }}},[_c('div',{staticClass:"progress-content d-flex"},[_c('span',[(!_vm.checkout)?_c('p',[_vm._v("1")]):_c('check-icon')],1),_c('p',{staticClass:"sbold"},[_vm._v(" "+_vm._s(_vm.$t('page.checkout.detailsHeading'))+" ")])])]):_c('div',{staticClass:"progress-content d-flex"},[_c('span',[(!_vm.checkout)?_c('p',{staticClass:"sm-sbold"},[_vm._v("1")]):_c('check-icon')],1),_c('p',{staticClass:"sm-sbold"},[_vm._v(" "+_vm._s(_vm.$t('page.checkout.detailsHeading'))+" ")])])],1),_c('arrow-right-icon'),_c('div',{staticClass:"progress-item",class:{
            selected: _vm.currentRouteName === 'Summar',
            completed: _vm.summary == true,
        }},[_c('div',{staticClass:"progress-content d-flex"},[_c('span',[(!_vm.summary)?_c('p',{staticClass:"sm-sbold"},[_vm._v("2")]):_c('check-icon')],1),_c('p',{staticClass:"sm-sbold"},[_vm._v(" "+_vm._s(_vm.$t('page.checkout.summaryHeading'))+" ")])])]),_c('arrow-right-icon'),_c('div',{staticClass:"progress-item",class:{ selected: _vm.currentRouteName === 'Confirmare' }},[_c('div',{staticClass:"progress-content d-flex"},[_vm._m(0),_c('p',{staticClass:"sm-sbold"},[_vm._v(" "+_vm._s(_vm.$t('page.checkout.sentOrderHeading'))+" ")])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('p',{staticClass:"sm-sbold"},[_vm._v("3")])])
}]

export { render, staticRenderFns }