<template>
    <b-row class="mt-">
        <b-col cols="12">
            <div class="heading d-flex">
                <p class="sbold">
                    {{ $t('page.summary.infoText') }}
                </p>
                <p
                    v-if="summaryData.shipping_method"
                    class="sm shipping"
                >
                    {{ summaryData.shipping_method.description }}
                </p>
            </div>
            <div class="content">
                <div
                    v-for="(product, index) in summaryData.items.products"
                    :key="`summary-product-item-${index}`"
                    class="item d-flex"
                >
                    <div class="d-flex">
                        <div class="image">
                            <img
                                :src="product.thumbnail"
                                :alt="product.name"
                            >
                        </div>
                        <p class="sm-sbold">
                            {{ product.quantity }} x {{ product.name }}
                        </p>
                    </div>
                    <p
                        v-dompurify-html="formatPrice(product.total_price)"
                        class="has-sup sm-sbold right"
                    />
                </div>
            </div>
            <div class="content">
                <div
                    v-for="(voucher, index) in summaryData.items.vouchers"
                    :key="`summary-voucher-item-${index}`"
                    class="item d-flex"
                >
                    <div>
                        <div class="image">
                            <img
                                :src="voucher.thumbnail"
                                :alt="voucher.name"
                            >
                        </div>
                        <p class="sm-sbold">
                            {{ voucher.quantity }} x {{ voucher.name }}
                        </p>
                    </div>
                    <p
                        v-dompurify-html="formatPrice(voucher.total_price)"
                        class="has-sup sm-sbold right"
                    />
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'SummaryItemsListing',
        computed: {
            ...mapState('checkout', ['summaryData', 'loading']),
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>
<style scoped lang="scss">
.heading {
  margin: 40px 0px 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-thin;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  p {
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .btn {
    margin: 0px;
  }
  .shipping {
    letter-spacing: 0;
    text-transform: none;
    color: $gray-dark;
    font-size: 14px;
  }
}
.content {
  .item {
    justify-content: space-between;
    padding: 5px 0px;
    align-items: center;
    p {
      &:first-of-type {
        max-width: 600px;
        letter-spacing: 1px;
        color: $text;
      }
      &.right {
        text-align: right;
      }
    }
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
    .image {
      background-color: $gray-thin;
      height: 60px;
      min-width: 90px;
      border-radius: 4px;
      @include media-breakpoint-down(sm) {
        max-height: 60px;
      }
      img {
        object-fit: contain;
      }
    }
  }
}
</style>
