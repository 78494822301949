<template>
    <div class="row mt-4">
        <div v-if="summaryData.applied_coupon && summaryData.applied_coupon.valid">
            <div class="heading d-flex coupon">
                <p class="sm-sbold">
                    {{
                        $t('page.summary.couponText', {
                            name: summaryData.applied_coupon.name,
                        })
                    }}
                </p>
                <p
                    v-dompurify-html="
                        formatPrice(-Math.abs(summaryData.applied_coupon.value))
                    "
                    class="has-sup sm-sbold"
                />
            </div>
        </div>
        <div v-if="summaryData.ramburs_fee > 0">
            <div class="heading d-flex ramburs">
                <p class="sm-sbold">
                    {{ $t('page.summary.rambursFeeText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.ramburs_fee)"
                    class="has-sup sm-sbold"
                />
            </div>
        </div>
        <div v-if="summaryData.shipping_method">
            <div class="heading d-flex transport">
                <p class="sm-sbold">
                    {{
                        $t('page.summary.transportText', {
                            name: summaryData.shipping_method.name,
                        })
                    }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.shipping_method.price)"
                    class="has-sup sm-sbold"
                />
            </div>
        </div>
        <div v-if="summaryData.extra_km_cost > 0">
            <div class="heading d-flex extra-km">
                <p class="sm-sbold">
                    {{
                        $t('page.summary.extraKmText', {
                            name: summaryData.shipping_method.name,
                        })
                    }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.extra_km_cost)"
                    class="has-sup sm-sbold"
                />
            </div>
        </div>
        <div v-if="summaryData.total_price">
            <div class="heading d-flex total">
                <p class="sbold">
                    {{ $t('page.summary.totalText') }}
                </p>
                <p
                    v-dompurify-html="formatPrice(summaryData.total_price)"
                    class="has-sup sbold"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import { splitedPrice } from '@/services/getSplitedPrice';
    export default {
        name: 'SummaryTotal',
        computed: {
            ...mapState('checkout', ['summaryData']),
        },
        methods: {
            formatPrice(price) {
                return splitedPrice(price);
            },
        },
    };
</script>
<style scoped lang="scss">
.row {
  > div {
    &:last-of-type {
      .heading {
        padding: 12px 0px;
        border-top: 1px solid $gray-thin;
      }
    }
    .heading {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      &.coupon,
      &.ramburs,
      &.transport,
      &.extra-km {
        p {
          color: $text;
          &:last-of-type {
            min-width: fit-content;
          }
        }
      }
      &.total {
        p {
          color: $primary;
          letter-spacing: 2px;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
