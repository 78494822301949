<template>
    <div class="d-flex mt-2">
        <img
            src="/images/Visa.webp"
            alt="card visa"
        >
        <img
            src="/images/MasterCard.webp"
            alt="card master"
        >
        <img
            src="/images/Maestro.webp"
            alt="card maestro"
        >
    </div>
</template>

<script>
    export default {
        name: 'CardTypes',
    };
</script>
