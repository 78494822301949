<template>
    <b-row class="mt-2">
        <b-col
            cols="12"
            md="4"
        >
            <div class="heading d-flex">
                <p class="sbold">
                    {{ $t('page.summary.shippingAddressHeading') }}
                </p>
                <router-link
                    v-slot="{ navigate }"
                    :to="{ name: 'Checkout', hash: '#delivery' }"
                    custom
                >
                    <b-button
                        variant="text"
                        class="sm stroke"
                        @click="navigate"
                    >
                        {{ $t('page.summary.changeButton') }}
                    </b-button>
                </router-link>
            </div>
            <div class="content">
                <caption>
                    {{
                        summaryDeliveryData.contact_name
                    }}
                </caption>
                <caption>
                    {{
                        summaryDeliveryData.contact_county
                    }},
                    {{
                        summaryDeliveryData.contact_city
                    }}
                </caption>
                <caption>
                    {{
                        summaryDeliveryData.contact_postcode
                    }},
                    {{
                        summaryDeliveryData.contact_address
                    }}
                </caption>
                <caption>
                    {{
                        summaryDeliveryData.contact_phone
                    }}
                </caption>
            </div>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
            <div class="heading d-flex">
                <p class="sbold">
                    {{ $t('page.summary.billingAddressHeading') }}
                </p>
                <router-link
                    v-slot="{ navigate }"
                    :to="{ name: 'Checkout', hash: '#billing' }"
                    custom
                >
                    <b-button
                        variant="text"
                        class="sm stroke"
                        @click="navigate"
                    >
                        {{ $t('page.summary.changeButton') }}
                    </b-button>
                </router-link>
            </div>
            <div
                v-if="selectedData.person_type == 'individual'"
                class="content"
            >
                <caption>
                    {{
                        summaryBillingData.billing_name
                    }}
                </caption>
                <caption>
                    {{
                        summaryBillingData.billing_county
                    }},
                    {{
                        summaryBillingData.billing_city
                    }}
                </caption>
                <caption>
                    {{
                        summaryBillingData.billing_postcode
                    }},
                    {{
                        summaryBillingData.billing_address
                    }}
                </caption>
                <caption>
                    {{
                        summaryBillingData.billing_phone
                    }}
                </caption>
            </div>
            <div v-else>
                <caption>
                    {{
                        summaryCompanyData.company_name
                    }},
                    {{
                        summaryCompanyData.company_account
                    }},
                </caption>
                <caption>
                    {{
                        summaryCompanyData.company_cif
                    }},
                    {{
                        summaryCompanyData.company_regnr
                    }}
                </caption>
                <caption>
                    {{
                        summaryCompanyData.company_bank
                    }}
                </caption>
                <caption>
                    {{
                        summaryCompanyData.company_county
                    }},
                    {{
                        summaryCompanyData.company_city
                    }}
                </caption>
                <caption>
                    {{
                        summaryCompanyData.company_address
                    }}
                </caption>
            </div>
        </b-col>
        <b-col
            cols="12"
            md="4"
        >
            <div class="heading d-flex">
                <p class="sbold">
                    {{ $t('page.summary.paymentOptionsHeading') }}
                </p>
                <router-link
                    v-slot="{ navigate }"
                    :to="{ name: 'Checkout', hash: '#payment' }"
                    custom
                >
                    <b-button
                        variant="text"
                        class="sm stroke"
                        @click="navigate"
                    >
                        {{ $t('page.summary.changeButton') }}
                    </b-button>
                </router-link>
            </div>
            <div class="content">
                <caption>
                    {{
                        paymentHeading
                    }}
                </caption>
                <caption>
                    {{
                        paymentDescription
                    }}
                </caption>
            </div>
            <card-types v-if="summaryData.payment_method == 'online'" />
        </b-col>
    </b-row>
</template>

<script>
    import { mapState } from 'vuex';

    import CardTypes from '@/components/checkout/CardTypes';

    export default {
        components: { CardTypes },
        name: 'SummaryInfo',
        computed: {
            ...mapState('checkout', [
                'summaryDeliveryData',
                'summaryBillingData',
                'summaryCompanyData',
                'summaryData',
                'selectedData',
            ]),
            paymentHeading() {
                return this.summaryData.payment_method == 'online'
                    ? this.$t('page.checkout.onlinePaymentText')
                    : this.$t('page.summary.rambursPaymentText', {
                        value: this.summaryData.ramburs_fee,
                    });
            },
            paymentDescription() {
                return this.summaryData.payment_method == 'online'
                    ? this.$t('page.summary.onlinePaymentText')
                    : '';
            },
        },
    };
</script>
<style scoped lang="scss">
.content {
  caption {
    display: block;
    width: 100%;
    margin: 2px 0px;
    &:last-of-type {
      margin-top: 10px;
    }
  }
}
.heading {
  padding: 12px 0px;
  border-bottom: 1px solid $gray-thin;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  p {
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  .btn {
    margin: 0px;
  }
}
</style>
