<template>
    <div class="checkout-progress-bar mt-3">
        <div
            class="progress-item d-flex"
            :class="{
                selected: currentRouteName === 'Checkout',
                completed: checkout == true,
            }"
        >
            <router-link
                v-if="currentRouteName === 'Summar'"
                :to="{ name: 'Checkout' }"
            >
                <div class="progress-content d-flex">
                    <span>
                        <p v-if="!checkout">1</p>
                        <check-icon v-else />
                    </span>
                    <p class="sbold">
                        {{ $t('page.checkout.detailsHeading') }}
                    </p>
                </div>
            </router-link>
            <div
                v-else
                class="progress-content d-flex"
            >
                <span>
                    <p
                        v-if="!checkout"
                        class="sm-sbold"
                    >1</p>
                    <check-icon v-else />
                </span>
                <p class="sm-sbold">
                    {{ $t('page.checkout.detailsHeading') }}
                </p>
            </div>
        </div>
        <arrow-right-icon />
        <div
            class="progress-item"
            :class="{
                selected: currentRouteName === 'Summar',
                completed: summary == true,
            }"
        >
            <div class="progress-content d-flex">
                <span>
                    <p
                        v-if="!summary"
                        class="sm-sbold"
                    >2</p>
                    <check-icon v-else />
                </span>
                <p class="sm-sbold">
                    {{ $t('page.checkout.summaryHeading') }}
                </p>
            </div>
        </div>
        <arrow-right-icon />
        <div
            class="progress-item"
            :class="{ selected: currentRouteName === 'Confirmare' }"
        >
            <div class="progress-content d-flex">
                <span>
                    <p class="sm-sbold">3</p>
                </span>
                <p class="sm-sbold">
                    {{ $t('page.checkout.sentOrderHeading') }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import ArrowRightIcon from 'vue-feather-icons/icons/ArrowRightIcon';
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    export default {
        name: 'CheckoutStatusProgressBar',
        components: { CheckIcon, ArrowRightIcon },
        data() {
            return {
                checkout: false,
                summary: false,
            };
        },
        computed: {
            currentRouteName() {
                return this.$route.name;
            },
        },
        mounted() {
            if (this.$route.name === 'Summar') {
                this.checkout = true;
            } else if (this.$route.name === 'Confirmare') {
                this.checkout = true;
                this.summary = true;
            }
        },
    };
</script>
<style scoped lang="scss">
.checkout-progress-bar {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(sm) {
    gap: 24px;
  }
  @include media-breakpoint-down(sm) {
    gap: 4px;
  }
  svg {
    height: 16px;
    width: 16px;
    color: $gray-light;
  }
  .progress-item {
    &:first-of-type {
      .progress-content {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
    }
    &:last-of-type {
      .progress-content {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
    .progress-content {
      gap: 12px;
      align-items: center;
      overflow: hidden;
      flex-direction: column;
      > p {
        text-transform: uppercase;
        color: $text;
        font-size: 10px;
        letter-spacing: 1px;
        line-height: 12px;
        text-align: center;
      }
      span {
        height: 24px;
        width: 24px;
        border-radius: 50%;
        background-color: $gray-light;
        display: flex;
        align-items: center;
        justify-content: center;
        p {
          text-align: center;
          color: $text;
          font-weight: 600;
          line-height: inherit;
        }
      }
    }
    &.selected {
      span {
        background-color: $primary;
        position: relative;
        // &:after {
        //   content: '';
        //   position: absolute;
        //   right: -8px;
        //   top: 10px;
        //   width: 0px;
        //   height: 0px;
        //   border-top: 7px solid transparent;
        //   border-bottom: 7px solid transparent;
        //   border-left: 8px solid $primary;
        // }
        svg {
          stroke: $white;
        }
        p {
          color: $white;
        }
      }
    }
    &.completed {
      span {
        background-color: $primary-75;
        svg {
          stroke: $white;
        }
      }
    }
  }
}
</style>
