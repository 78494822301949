<template>
    <div class="container checkout">
        <loading-spinner
            v-if="loading"
            class="fixed"
        />
        <b-row
            :class="{ transparent: loading }"
        >
            <b-col
                cols="12"
                class="page-header"
            >
                <h4>{{ $t('page.summary.heading') }}</h4>
                <checkout-status-progress-bar />
            </b-col>
        </b-row>
        <summary-info
            v-if="existData"
            :class="{ transparent: loading }"
        />
        <summary-items-listing
            v-if="existData"
            :class="{ transparent: loading }"
        />
        <summary-total
            v-if="existData"
            :class="{ transparent: loading }"
        />
        <summary-action
            v-if="existData"
            :class="{ transparent: loading }"
        />
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapState } from 'vuex';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import CheckoutStatusProgressBar from './checkout/CheckoutStatusProgressBar';
    import SummaryAction from './summary/SummaryAction';
    import SummaryInfo from './summary/SummaryInfo';
    import SummaryItemsListing from './summary/SummaryItemsListing';
    import SummaryTotal from './summary/SummaryTotal';
    export default {
        components: {
            CheckoutStatusProgressBar,
            SummaryInfo,
            SummaryItemsListing,
            SummaryTotal,
            SummaryAction,
            LoadingSpinner,
        },
        name: 'Summar',
        data() {
            return {
                loading: true,
            };
        },
        computed: {
            ...mapState('checkout', [
                'summaryError',
                'summaryDeliveryData',
                'summaryBillingData',
                'summaryCompanyData',
                'summaryData',
            ]),
            lang() {
                return i18n.locale;
            },
            existData() {
                return (
                    Object.keys(this.summaryData).length > 0 &&
                    this.summaryDeliveryData && (this.summaryBillingData || this.summaryCompanyData)
                );
            },
        },
        methods: {
            ...mapActions('checkout', ['getSummaryData']),
            async loadData() {
                await this.getSummaryData();
                if(this.summaryData.applied_coupon && !this.summaryData.applied_coupon.valid){
                    this.$toast.open({
                        message: this.$t('notification.promoCodeRemoved', {
                            name: this.summaryData.applied_coupon.name,
                        }),
                        duration: 10000,
                        position: 'bottom-right',
                        type: 'info',
                    });
                }
                if (this.summaryError) {
                    this.$router.push({ name: 'Checkout' }).catch(() => {
                        //
                    });
                } else {
                    this.loading = false;
                }
            },
        },
        created() {
            this.loadData();
        },
        metaInfo() {
            return {
                title: this.$t('meta.summary.title'),
                meta: [
                    {
                        name: 'robots',
                        content: 'noodp',
                    },
                    {
                        vmid: 'description',
                        name: 'description',
                        content: '',
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: '',
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: '',
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: '',
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: '',
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: '',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: '',
                    },
                ],
            };
        },
        watch: {
            lang: {
                handler() {
                    this.loadData();
                },
                deep: true,
            },
        },
    };
</script>
