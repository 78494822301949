<template>
    <b-row class="mt-4">
        <b-col cols="12">
            <div class="send-order">
                <caption>
                    {{
                        $t('page.summary.termsText')
                    }}
                </caption>
                <div>
                    <b-button
                        variant="primary"
                        class="fill lg"
                        :disabled="disabled"
                        @click="send()"
                    >
                        <span>{{ $t('page.summary.placeOrderButton') }}</span><arrow-right-icon />
                    </b-button>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import ArrowRightIcon from 'vue-feather-icons/icons/ArrowRightIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        components: { ArrowRightIcon },
        name: 'SummaryAction',
        data() {
            return {
                disabled: false,
            };
        },
        computed: {
            ...mapState('checkout', ['sendingError', 'orderId']),
        },
        methods: {
            ...mapActions('checkout', ['sendOrder']),
            ...mapMutations('user', ['setModal']),
            ...mapMutations('general', { setGeneralModal: 'setModal' }),
            async send() {
                let permitedPaymentMethods = ['ramburs', 'online'];
                let permitedShippingMethods = [
                    'fan-courier',
                    'sameday',
                    'predare-personala',
                    'email',
                ];
                let permitedPersonTypes = ['individual', 'legal'];
                let permitedSameData = ['true', 'false'];
                let deliveryMethodString = localStorage.getItem('deliveryMethod');
                let deliveryMethod;

                try {
                    deliveryMethod = JSON.parse(deliveryMethodString);
                } catch (error) {
                    deliveryMethod = { code: 'error' };
                }

                let paymentType = localStorage.getItem('paymentType');
                let personType = localStorage.getItem('personType');
                let sameData = localStorage.getItem('sameData');
                let error = false;

                if (!permitedShippingMethods.includes(deliveryMethod.code)) {
                    this.$toast.open({
                        message: this.$t('notification.deliveryMethodMissing'),
                        type: 'error',
                        duration: 5000,
                    });
                    error = true;
                }
                if (!permitedPaymentMethods.includes(paymentType)) {
                    this.$toast.open({
                        message: this.$t('notification.paymentTypeMissing'),
                        type: 'error',
                        duration: 6000,
                    });
                    error = true;
                }
                if (!permitedPersonTypes.includes(personType)) {
                    this.$toast.open({
                        message: this.$t('notification.personTypeMissing'),
                        type: 'error',
                        duration: 7000,
                    });
                    error = true;
                }
                if (!permitedSameData.includes(sameData)) {
                    this.$toast.open({
                        message: this.$t('notification.sameData'),
                        type: 'error',
                        duration: 8000,
                    });
                    error = true;
                }
                if (!error) {
                    this.disabled = true;
                    await this.sendOrder();
                    if(this.sendingError){
                        this.$router.push({ name: 'Checkout' }).catch(() => {
                            //
                        });
                    }
                    if (!this.sendingError && paymentType === 'ramburs') {
                        this.$router.push({ name: 'DetaliiComanda', params: { id: this.orderId } }).catch(() => {
                            //
                        });
                        this.setModal({
                            show: true,
                            type: 'orderSuccess'
                        });
                    }
                }
            },
        },
    };
</script>
<style scoped lang="scss">
.send-order {
  caption {
    text-align: center;
  }
  .btn {
    margin: 12px auto;
    width: 30%;
    min-width: 220px;
  }
}
</style>
